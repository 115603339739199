<template>
  <b-sidebar id="add-edit-subject-sidebar" :visible="isAddEditSubjectSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-subject-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Edit Subject</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <zape-gallery-model :props_hideShowModel="showImageModel" @modelClose="modelClose" @saveCategoryImage="saveImage"
        :keyForLottie="keyForLottie" />
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <validation-provider #default="validationContext" name="Subject Name" rules="required">
            <b-form-group label="Subject Name" label-for="Subject Name">
              <b-form-input id="Subject-Name" v-model="SubjectData.subject_name" autofocus
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Sort Subject" rules="required">
            <b-form-group label="Sort Subject" label-for="Sort Subject">
              <b-form-input id="Sort-Subject" v-model="SubjectData.subject_order" autofocus
                :state="getValidationState(validationContext)" trim type="number" placeholder="Enter Numeric Value" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <br />
          <!-- <img style="width: 200px; height: 150px" :src="SubjectData.subject_img" alt="" /> -->
          <template v-if="getFileExtensionFromUrl(SubjectData.subject_img) == 'json'">
            <LottieView :url="SubjectData.subject_img" :forEditTopics="true" :key="keyForLottie" />
          </template>
          <template v-else-if="getFileExtensionFromUrl(SubjectData.subject_img) == 'lottie'">
            <!-- <LottieView :url="SubjectData.subject_img" :forEditTopics="true" :key="keyForLottie" /> -->
            <DoLottieView :url="SubjectData.subject_img" :forEditTopics="true" :id="`subject_img_${uuidv4()}`"
              :key="keyForLottie" />
          </template>
          <template v-else>
            <img style="width: 200px; height: 150px" :src="SubjectData.subject_img" />
          </template>
          <br />
          <br />
          <div class="d-flex gap-2">
            <b-button variant="primary" @click="showgalleryimage">
              <span class="d-none d-sm-inline">Image gallery</span>
            </b-button>
            <upload-image @saveUploadImage="saveUploadImage" />
          </div>
          <div class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" @click="hide"
              :disabled="submitbutton">
              Update
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" style="margin-left: 10px"
              variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { numeric } from "vuelidate/lib/validators";
import LottieView from "@/views/components/question/LottieView.vue";
import DoLottieView from "@/views/components/question/DoLottieView.vue";
import { v4 as uuidv4 } from 'uuid';
export default {
  components: {
    ZapeGalleryModel: () => import("../../../components/Model/ZapeGalleryModel.vue"),
    UploadImage: () => import("../../../components/Model/UploadImage.vue"),
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    LottieView,
    DoLottieView,
    uuidv4
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditSubjectSidebarActive",
    event: "update:is-add-edit-subject-sidebar-active",
  },
  props: {
    isAddEditSubjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    Editclassdata: {
      type: String,
      required: true,
    },
    Editsubjectid: {
      type: String,
      required: true,
    },
    EditsubjectOrder: {
      type: numeric,
      required: true,
    },
    Editsubjectname: {
      type: String,
      required: true,
    },
    Editsubjectimg: {
      type: String,
      required: true,
    },
  },
  watch: {
    Editsubjectname() {
      this.SubjectData.subject_name = this.Editsubjectname;
    },
    Editsubjectimg() {
      this.SubjectData.subject_img = this.Editsubjectimg;
    },
    EditsubjectOrder() {
      this.SubjectData.subject_order = this.EditsubjectOrder;
    },
  },
  data() {
    return {
      required,
      SubjectData: {
        subject_name: "",
        subject_img: "",
        subject_order: 1,
      },
      orginal_name: "",
      edit_subject_data: "",
      showImageModel: false,
      keyForLottie: 0
    };
  },
  computed: {
    submitbutton() {
      return !(this.SubjectData.subject_name.length > 0 &&
        this.SubjectData.subject_img.length > 0 &&
        this.SubjectData.subject_order > 0);
    }
  },

  methods: {
    uuidv4,
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getFileExtensionFromUrl(url) {
      if (!url) return '';
      const path = url.split('?')[0].split('#')[0];
      const extension = path.split('.').pop();

      if (extension === path) return '';

      return extension;
    },

    resetForm() {
      this.SubjectData = {
        subject_name: this.Editsubjectname,
        subject_img: this.Editsubjectimg,
        subject_order: this.EditsubjectOrder,
      };
      this.validated = false;
    },
    onSubmit() {
      this.SubjectData.id = this.Editclassdata;
      this.SubjectData.subject_id = this.Editsubjectid;
      this.edit_subject_data = this.SubjectData;
      this.$emit("clickeditsubjectdata", this.edit_subject_data);
    },
    saveUploadImage(url) {
      console.log('url', url)
      this.SubjectData.subject_img = url;
      this.keyForLottie += 1;
    },
    modelClose(modelState) {
      this.showImageModel = modelState;
    },
    saveImage(url) {
      this.SubjectData.subject_img = url;
      this.keyForLottie += 1;
    },
    showgalleryimage() {
      this.showImageModel = true;
    },
  },
};
</script>
