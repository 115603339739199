<template>

  <div>
    <canvas :id="id" :style="lottieStyle" :key="keyForLottie"></canvas>
  </div>
</template>

<script>

import { DotLottie } from '@lottiefiles/dotlottie-web';
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    keyForLottie: {
      type: Number,
      required: false
    },

    forModel: {
      type: Boolean,
      required: false
    },
    forPreview: {
      type: Boolean,
      required: false
    },
    forEditTopics: {
      type: Boolean,
      required: false
    },
    forTopic: {
      type: Boolean,
      required: false
    },
    galleryLottie: {
      type: Boolean,
      required: false
    },

  },


  data() {
    return {
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.renderLottie(this.url);
    });
  },

  computed: {
    lottieStyle() {
      if (this.forPreview) {
        return { width: "100%", height: "100%" };
      } else if (this.forEditTopics) {
        return { width: "200px", height: "150px" };
      } else if (this.forTopic) {
        return { width: "100px", height: "90px", margin: '0px' };
      } else {
        return {
          width: this.galleryLottie ? "100px" : this.forModel ? "200px" : "30px",
          height: this.galleryLottie ? "100px" : this.forModel ? "200px" : "30px",
        };
      }
    },

  },
  watch: {
    url(value) {
      this.$nextTick(() => {
        this.renderLottie(value);
      });
    }
  },

  methods: {
    renderLottie(src) {
      if (!src && !this.id) {
        return;
      }
      new DotLottie({
        autoplay: true,
        loop: true,
        canvas: document.querySelector(`#${this.id}`),
        src
      });

    }
  }

};
</script>
