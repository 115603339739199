<template>
    <div>
        <div v-if="lottieJson">
            <Lottie :options="lottieOptions" :style="lottieStyle" :key="keyForLottie" />
        </div>
        <div v-else>
            <img src="https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp" alt="Animated loader"
                style="width: 100px;height: 100px;" />
        </div>


    </div>
</template>

<script>
import axios from 'axios';
import Lottie from 'vue-lottie';

export default {
    props: {
        url: {
            type: String,
            required: true
        },
        forModel: {
            type: Boolean,
            required: false
        },
        forPreview: {
            type: Boolean,
            required: false
        },
        forEditTopics: {
            type: Boolean,
            required: false
        },
        forTopic: {
            type: Boolean,
            required: false
        },
        keyForLottie: {
            type: Number,
            required: false
        },
        galleryLottie: {
            type: Boolean,
            required: false
        },
    },
    components: {
        Lottie
    },
    data() {
        return {
            lottieJson: null
        };
    },
    computed: {

        lottieOptions() {
            return {
                loop: true,
                autoplay: true,
                animationData: this.lottieJson
            };
        },
        lottieStyle() {
            if (this.forPreview) {
                return { width: "100%", height: "100%" };
            } else if (this.forEditTopics) {
                return { width: "200px", height: "150px" };
            } else if (this.forTopic) {
                return { width: "100px", height: "90px", margin: '0px' };
            } else {
                return {
                    width: this.galleryLottie ? "100px" : this.forModel ? "200px" : "30px",
                    height: this.galleryLottie ? "100px" : this.forModel ? "200px" : "30px",
                };
            }
        },

    },
    created() {
        this.getLottieOptions(this.url);
    },
    methods: {
        getLottieOptions(url) {

            axios.get(url)
                .then(response => {
                    this.lottieJson = response.data;
                })
                .catch(error => {
                    console.error('Error fetching Lottie data:', error);
                });
        },

    }
};
</script>

<style>
/* Add any necessary styles here */
</style>